import "../styles/pages/page-404.scss";
import React from "react";
import { Link } from "gatsby";
import Layout from "components/Layout";
import Seo from "components/Seo";

const NotFoundPage = () => {
   return (
      <Layout>
         <Seo title="Nie znaleziono takiej strony" />
         <div className="container">
            <section className="page-404">
               <h1 className="page-404__header">
                  <span>4</span>
                  <span>0</span>
                  <span>4</span>
               </h1>
               <h3 className="page-404__subheader">Nie znaleziono strony</h3>
               <Link to="/" className="button page-404__button">
                  Powrót
               </Link>
            </section>
         </div>
      </Layout>
   );
};

export default NotFoundPage;
